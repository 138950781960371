<template>
  <div>
    <BCard
      :id="htmlID"
      header-tag="h2"
      header-bg-variant="ams-gray"
      header-border-variant="ams-header-orange"
      header-text-variant="black"
      header-class="border-bottom highlight-border"
      align="left"
      class="m3 shadow mt-3"
    >
      <template slot="header">
        <span v-if="basicInfo.earliest.year && basicInfo.earliest.year != '-' && basicInfo.latest.year && basicInfo.latest.year != '-'">
          {{ basicInfo.earliest.year }} to {{ latestYear }} -
        </span>
        <!-- <a
          :href="'profile?' + serialType + 'Id=' + serialId"
          class="card-title-url"
        >
          {{ basicInfo.abbr }}
        </a> -->
        <RouterLink
          :to="{name: 'SerialProfile', query: {[serialType + 'Id']: serialId}}"
          class="card-title-url"
        >
          {{ basicInfo.abbr }}
        </RouterLink>
      </template>
      <BTabs
        style="width:100%;"
      >
        <!-- Tab Names -->
        <BTab>
          <template slot="title">
            <span
              v-if="!isSeries"
              class="d-none d-sm-inline-block"
            >
              Journal
            </span>
            <span
              v-if="!isSeries"
              class="d-inline-block d-sm-none"
            >
              Jour.
            </span>
            <span
              v-if="isSeries"
              class="d-none d-sm-inline-block"
            >
              Series
            </span>
            <span
              v-if="isSeries"
              class="d-inline-block d-sm-none"
            >
              Ser.
            </span>
          </template>
          <Msntable :rows="detailsTable" />
        </BTab>
        <BTab>
          <template slot="title">
            <span class="d-none d-sm-inline-block">
              Related
            </span>
            <span class="d-inline-block d-sm-none">
              Rel.
            </span>
          </template>
          <div v-if="basicInfo.related && basicInfo.related.length > 0">
            <Msntable :rows="relationTable" />
          </div>
          <div v-else>
            <i>No relations found</i>
          </div>
        </BTab>
        <BTab
          v-if="basicInfo.earliest.year !== '-' || basicInfo.latest.year !== '-' || basicInfo.count > 0"
        >
          <template slot="title">
            <div v-if="!isSeries && basicInfo.count > 0">
              <span class="d-none d-sm-inline-block">
                Issues
              </span>
              <span class="d-inline-block d-sm-none">
                Iss.
              </span>
            </div>
            <div v-if="!isSeries && basicInfo.count == 0">
              <span class="d-none d-sm-inline-block">
                Publications
              </span>
              <span class="d-inline-block d-sm-none">
                Pub.
              </span>
            </div>
            <div v-if="isSeries">
              <span class="d-none d-sm-inline-block">
                Volumes
              </span>
              <span class="d-inline-block d-sm-none">
                Vol.
              </span>
            </div>
          </template>
          <Msntable :rows="pubTable" />
        </BTab>
        <BTab
          v-if="basicInfo.diffs != undefined && basicInfo.diffs !== {}"
          active
        >
          <template slot="title">
            <span class="d-inline-block hide-xxs">
              Changes
            </span>
            <span class="d-none show-xxs">
              &#916; <!-- Unicode delta -->
            </span>
          </template>
          <Msntable
            :rows="changesTable"
            :sm-two-col="true"
          />
        </BTab>
      </BTabs>
    </BCard>
    <!-- Split/Merge cards -->
    <div
      v-for="(mergeOrSplit, index) in mergesAndSplits"
      :key="index"
      :class="'timeline-obj-indented ' + mergeOrSplit.csstype"
    >
      <BCard
        header-bg-variant="ams-gray"
        header-border-variant="ams-orange"
        header-text-variant="black"
        header-class="border-bottom highlight-border"
        align="left"
        title=""
        class="m3 shadow mt-3"
      >
        <template slot="header">
          {{ mergeOrSplit.type.split(' ')[0] }}
        </template>
        <div v-if="mergeOrSplit.type.indexOf('into') > -1">
          <i>
            {{ basicInfo.abbr }}
          </i>
          <b v-if="mergeOrSplit.type.split(' ')[0] === 'Merged'">
            merged into
          </b>
          <b v-else>
            split into
          </b>
          <i>
            <RouterLink :to="mergeOrSplit.to">
              {{ mergeOrSplit.text }}
            </RouterLink>
            <!-- <a :href="mergeOrSplit.url">
              {{ mergeOrSplit.text }}
            </a> -->
          </i>
        </div>
        <div v-else-if="mergeOrSplit.type.indexOf('from')">
          <i>
            <RouterLink :to="mergeOrSplit.to">
              {{ mergeOrSplit.text }}
            </RouterLink>
            <!-- <a :href="mergeOrSplit.url">
              {{ mergeOrSplit.text }}
            </a> -->
          </i>
          <b v-if="mergeOrSplit.type.split(' ')[0] === 'Merged'">
            merged into
          </b>
          <b v-else>
            split into
          </b>
          <i>
            {{ basicInfo.abbr }}
          </i>
        </div>
      </BCard>
    </div>
  </div>
</template>
<script>
import { formatName, genRelatedTable } from '@/utils'

// import URLGen from '@/utils/url-gen'

export default {
  components: {
    Msntable: () => import('@/components/journal/MsnTable.vue'),
    // Card: () => import(/* webpackChunkName: "journal_card" */ '@/components/journal/JournalCard.vue'),
  },
  props: {
    serialType: {
      type: String,
      required: true,
    },
    basicInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isSeries: function() {
      return this.serialType === 'series'
    },
    serialId: function() {
      return this.isSeries ? this.basicInfo.seriesId : this.basicInfo.journalId
    },
    latestYear: function() {
      if (this.basicInfo.current) {
        return 'Present'
      } else {
        return this.basicInfo.latest.year
      }
    },
    htmlID: function() {
      // return this.serialType + this.serialId
      if (this.isSeries) {
        return 's' + this.serialId
      } else {
        return 'j' + this.serialId
      }
    },
    serialPubType: function() {
      return this.isSeries ? 'Volume' : this.basicInfo.count > 0 ? 'Issue' : 'Publication'
    },
    detailsTable: function() {
      const table = []
      table.push([
        'Title',
        formatName(this.basicInfo.title),
      ])
      table.push([
        this.mobileAndDesktopHTML('Abbreviation', 'Abbr.'),
        this.basicInfo.abbr === null ? 'N/A' : formatName(this.basicInfo.abbr),
      ])
      table.push([
        this.mobileAndDesktopHTML('Publisher', 'Pub.'),
        this.basicInfo.publisher === null || this.basicInfo.publisher.length === 0 ? 'N/A' : formatName(this.basicInfo.publisher),
      ])

      if (this.serialType === 'journal') {
        table.push([
          this.mobileAndDesktopHTML('ISSN (Print)', 'ISSN (P)'),
          this.basicInfo.issn == null ? 'N/A' : this.basicInfo.issn,
        ])
        table.push([
          this.mobileAndDesktopHTML('ISSN (Online)', 'ISSN (O)'),
          this.basicInfo.eissn === null ? 'N/A' : this.basicInfo.eissn,
        ])
        table.push([
          this.mobileAndDesktopHTML('Frequency', 'Freq.'),
          this.basicInfo.frequency === null ? 'N/A' : this.basicInfo.frequency,
        ])
      } else {
        table.push([
          'ISSN',
          this.basicInfo.issn == null ? 'N/A' : this.basicInfo.issn,
        ])
      }

      return table
    },
    changesTable: function() {
      const table = []
      for (const i in this.basicInfo.diffs) {
        let label = ''
        let newValue = ''
        const oldValue = this.basicInfo.diffs[i] == null || this.basicInfo.diffs[i].length === 0 ? 'N/A' : formatName(this.basicInfo.diffs[i]) // Standard default, should always work

        switch (i) {
          case 'title':
            label = 'Title'
            newValue = formatName(this.basicInfo.title)
            break

          case 'abbr':
            label = this.mobileAndDesktopHTML('Abbreviation', 'Abbr.')
            newValue = this.basicInfo.abbr == null ? 'N/A' : formatName(this.basicInfo.abbr)
            break

          case 'publisher':
            label = this.mobileAndDesktopHTML('Publisher', 'Pub.')
            newValue = this.basicInfo.publisher == null || this.basicInfo.publisher.length === 0 ? 'N/A' : formatName(this.basicInfo.publisher)
            break

          case 'issn':
            label = 'ISSN'
            if (!this.isSeries) {
              label = this.mobileAndDesktopHTML('ISSN (Print)', 'ISSN (P)')
            }
            newValue = this.basicInfo.issn == null ? 'N/A' : this.basicInfo.issn
            break

          case 'eissn':
            label = this.mobileAndDesktopHTML('ISSN (Online)', 'ISSN (O)')
            newValue = this.basicInfo.eissn == null ? 'N/A' : this.basicInfo.issn
            break

          case 'frequency':
          case 'freq':
            label = this.mobileAndDesktopHTML('Frequency', 'Freq.')
            newValue = (this.basicInfo.frequency == null ? 'N/A' : this.basicInfo.frequency)
            break
        }

        table.push([
          label,
          this.formatFormerly(newValue, oldValue),
        ])
      }

      return table
    },
    relationTable: function() {
      const table = genRelatedTable(this.basicInfo.related, true, false, this.$router)
      return table
    },
    pubTable: function() {
      const table = []
      // const volIssInfo = {}
      // let target = URLGen.voliss

      // if (this.isSeries) {
      //   volIssInfo.seriesId = this.basicInfo.seriesId
      // } else {
      //   if (this.basicInfo.count > 0) {
      //     volIssInfo.journalId = this.basicInfo.journalId
      //   } else {
      //     target = URLGen.publications
      //     volIssInfo.yearRange = [this.basicInfo.earliest.year, this.basicInfo.latest.year]
      //     volIssInfo.jourAbbr = this.basicInfo.abbr
      //   }
      // }

      const toTarget = {
        name: '',
        query: {},
      }

      if (this.isSeries) {
        toTarget.name = 'VolumeList'
        toTarget.query.seriesId = this.basicInfo.seriesId
      } else {
        if (this.basicInfo.count > 0) {
          toTarget.name = 'IssueList'
          toTarget.query.journalId = this.basicInfo.journalId
        } else {
          toTarget.name = 'PublicationsSearch'
          toTarget.query.query = `y:[${this.basicInfo.earliest.year} ${this.basicInfo.latest.year}] j:(${this.basicInfo.abbr})`
        }
      }

      table.push([
        this.serialPubType + 's Listed',
        this.basicInfo.count == null ? 'N/A' : this.basicInfo.count > 0 ? {
          // url: target(volIssInfo),
          to: toTarget,
          text: this.basicInfo.count,
        } : {
          // url: target(volIssInfo),
          to: toTarget,
          text: 'Search',
        },
      ])

      table.push([
        'Latest ' + this.serialPubType,
        this.basicInfo.latest == null ? 'N/A' : {
          to: { name: 'PublicationsSearch', query: { query: this.basicInfo.latest.query } },
          text: this.basicInfo.latest.text,
        },
      ])
      table.push([
        'Earliest ' + this.serialPubType,
        this.basicInfo.earliest == null ? 'N/A' : {
          to: { name: 'PublicationsSearch', query: { query: this.basicInfo.earliest.query } },
          text: this.basicInfo.earliest.text,
        },
      ])
      return table
    },
    mergesAndSplits: function() {
      const merges = this.relationsOfType('Merged from').concat(this.relationsOfType('Merged into'))
      const splits = this.relationsOfType('Split into').concat(this.relationsOfType('Split from'))
      const mAndS = []

      for (const i in merges) {
        const details = {
          // type: 'merge',
          type: merges[i].reltype,
          text: merges[i].relabbrev,
          csstype: merges[i].reltype.indexOf('into') > -1 ? 'out-obj' : 'in-obj',
        }
        if (merges[i].reltype === 'SER') {
          // details.url = URLGen.history({ seriesId: merges[i].relid })
          details.to = {
            name: 'History',
            query: {
              seriesId: merges[i].relid,
            },
          }
        } else {
          // details.url = URLGen.history({ journalId: merges[i].relid })
          details.to = {
            name: 'History',
            query: {
              journalId: merges[i].relid,
            },
          }
        }
        mAndS.push(details)
      }

      for (const i in splits) {
        const details = {
          type: 'split',
          text: splits[i].relabbrev,
        }
        if (splits[i].reltype === 'SER') {
          // details.url = URLGen.history({ seriesId: splits[i].relid })
          details.to = {
            name: 'History',
            query: {
              seriesId: splits[i].relid,
            },
          }
        } else {
          // details.url = URLGen.history({ journalId: splits[i].relid })
          details.to = {
            name: 'History',
            query: {
              journalId: splits[i].relid,
            },
          }
        }
        mAndS.push(details)
      }

      return mAndS
    },
  },
  methods: {
    formatFormerly: function(newdata, olddata) {
      return '' + newdata + '<br><i>(formerly ' + olddata + ')</i>'
    },
    mobileAndDesktopHTML: function(full, short) {
      return '<span class="d-inline-block hide-xxs">' + full + '</span><span class="d-none show-xxs">' + short + '</span>'
    },
    relationsOfType: function(reltype) {
      const matches = []
      for (const i in this.basicInfo.related) {
        if (this.basicInfo.related[i].reltype === reltype) {
          matches.push(this.basicInfo.related[i])
        }
      }
      return matches
    },
  },
}
</script>
<style scoped>
  .card-header a:visited {
    color: black;
  }
</style>
